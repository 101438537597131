import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePageName } from "react-page-name";
import axios from "axios";
import "./viewFerienwohnung.scss";
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';

const ViewFerienwohnung = () => {
    const { t, i18n } = useTranslation();

    const [ferienwohnung, setFerienwohnung] = useState(null); // Initialize as null
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "de" ? "" : i18n.language + "/";

    const location = useLocation();
    const ferienwohnungSlug = location.pathname.split("/")[3];

    useEffect(() => {
        const fetchAllRef = async () => {
            try {
                // Fetch für Ferienwohnungen
                const ferienwohnungRes = await axios.get(`https://oberkroesser.com/wp-json/wp/v2/ferienwohnungen?slug=${ferienwohnungSlug}`);
                if (ferienwohnungRes.data && ferienwohnungRes.data.length > 0) {
                    setFerienwohnung(ferienwohnungRes.data[0]); // Set to first item if array is returned
                } else {
                    setFerienwohnung(null); // Set to null if no data
                }
            } catch (err) {
                console.log(err);
                setFerienwohnung(null); // Set to null in case of error
            } finally {
                setLoading(false); // Ensure loading is stopped in all cases
            }
        };

        fetchAllRef();
    }, [lang, ferienwohnungSlug]);

    usePageName(isLoading ? `${t("site.loading")} | ${t("site.pageName")}` : `${ferienwohnung?.title?.rendered || ''} | ${t("site.pageName")}`);

    return (
        <div className="viewFerienwohnung">
            {isLoading ? (
                <div className="loading" />
            ) : (
                <div className="container">
                    <div className="header">
                        <div className="container">
                            <h1>{ferienwohnung.title.rendered}</h1>
                        </div>
                    </div>
                    <div className="summary">
                        <div className="left">
                            <p>{ferienwohnung.acf.beschreibung}</p>
                            <span><OpenInFullOutlinedIcon /> {ferienwohnung.acf.size}</span>
                            <span><PeopleOutlinedIcon /> {ferienwohnung.acf.personen}</span>
                            <Link to={`/${i18n.language}/anfragen`}>Anfragen</Link>
                            <img src={ferienwohnung.acf.image} alt={ferienwohnung.title.rendered} />
                        </div>
                        <div className="right">
                            <img src={ferienwohnung.acf.image2} alt={ferienwohnung.title.rendered} />
                        </div>
                    </div>
                    <div className="wp-content" dangerouslySetInnerHTML={{ __html: ferienwohnung.content.rendered }} />
                </div>
            )}
        </div>
    );
};

export default ViewFerienwohnung;
