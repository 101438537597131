import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePageName } from "react-page-name";
import "./kontakt.scss";

const Kontakt = () => {
    const { t } = useTranslation();

    usePageName(t("site.kontakt") + " | " + t("site.pageName"));

    return (
        <div className="kontakt">
            <div className="header">
                <div className="container">
                    <h1>{t("site.kontakt")}</h1>
                </div>
            </div>
            <div className="container">
                <div className="info">
                    <div className="left">
                        <h2>{t("site.pageName")}</h2>
                        <p>{t("impressum.adresse")}</p>
                        <div className="links">
                            <Link to={"tel:+393470183939"}><button>+39 347 018 3939</button></Link>
                            <Link to={"mailto:info@oberkroesser.com"}><button>info@oberkroesser.com</button></Link>
                        </div>
                    </div>
                    <div className="right">
                        <img src="https://oberkroesser.com/wp-content/uploads/2024/08/20240709-MTN09827-scaled.jpg" alt="Kontakt" />
                    </div>
                </div>

                <div className="gastgeber">
                    <img src="https://oberkroesser.com/wp-content/uploads/2024/08/WhatsApp-Bild-2024-07-19-um-06.50.43_cff4fb98.jpg" alt="" />
                    <div className="right">
                        <h3>{t("kontakt.gastgeber")}:</h3>
                        <span>{t("kontakt.kontaktieren")}</span>
                        <div className="links">
                            <Link to={"tel:+393470183939"}><button>+39 347 018 3939</button></Link>
                            <Link to={"mailto:info@oberkroesser.com"}><button>info@oberkroesser.com</button></Link>
                        </div>
                    </div>
                </div>
                <iframe
                    allowFullScreen=""
                    frameBorder="0"
                    loading="lazy"
                    src="https://www.google.com/maps/embed/v1/search?key=AIzaSyDcHruo0hR5wUslR32AyekdXtG1PmpMcrk&amp;q=Oberkrösserhof+-+Urlaub+auf+dem+Bauernhof&amp;zoom=16"
                    title="Karte"
                    width="100%"
                    height="800px"
                />
            </div>
        </div>
    );
};

export default Kontakt;
